<template>
  <div class="admin-archived-devices">
    <div class="toolbar justify-content-between">
      <div>
        <i
          class="icon-button"
          :class="$config.icons.general.back"
          v-b-tooltip
          title="Back to Admin"
          @click="clickBack"
        ></i>
      </div>
      <div>
        <i
          class="icon-button"
          :class="$config.icons.general.refresh"
          v-b-tooltip
          title="Refresh"
          @click="refreshList"
        ></i>
        <i
          class="icon-button"
          :class="$config.icons.general.export"
          v-b-tooltip
          title="Export Table"
          @click="$bvModal.show('modal-export')"
        ></i>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Archived Devices</h2></b-card-header>
        <b-card-body>
          <admin-device-list
            v-on:change="selectChange"
            ref="adminDeviceList"
            archived-mode
            :show-fields="deviceFields"
            :filterDefaults="filterDefaults"
          ></admin-device-list>
        </b-card-body>
      </b-card>
    </div>
    <!--    Data Exporter Template-->
    <b-modal id="modal-export" centered class="modal-content" size="xl" hide-footer title="Export Data">
      <div v-if="$refs.adminDeviceList">
        <data-exporter :data="$refs.adminDeviceList.tableData" ></data-exporter>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AdminDeviceList from '../../components/admin/AdminDeviceList'
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
import * as DataProvider from '../../components/helpers/DataProvider'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import DataExporter from '@/components/DataExporter'

export default {
  name: 'admin-archived-devices',
  components: {
    AdminDeviceList,
    DataExporter
  },
  data: function () {
    return {
      selectedDevices: [],
      deviceFields: ['selected', 'archived', 'device_type', 'device_imei', 'device_code', 'name', 'device_phone', 'actions'],
      filterDefaults: {
        archived: true
      }
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    refreshList: function () {
      this.$refs.adminDeviceList.refreshDeviceList()
    },
    selectChange: function (newSelection) {
      this.selectedDevices = newSelection
    },
    deleteSelected: async function () {
      if (this.selectedDevices.length === 0) {
        ErrorHelper.displayGeneralErrorToast(
          "You don't have ay users selected to delete!",
          'No Users Selected'
        )
        return
      }

      let res = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete ${this.selectedDevices.length} Devices?`,
        {
          title: 'Confirm Delete',
          okVariant: 'danger',
          centered: true
        }
      )
      if (res) {
        for (let device of this.selectedDevices) {
          console.log('Archiving: ', device)
          let response = await DataProvider.adminArchiveDevice(device.device_imei)
          if (response.success) {
            AlertHelper.successToast('The Device was successfully deleted.', 'Delete Successful')
          } else {
            ErrorHelper.displayDataErrorToast(response)
          }
        }
        this.$refs.adminDeviceList.refreshDeviceList()
      }
    },
    onSave: function (data) {
      console.log('Save event detected')
      console.log({ data })
      this.refreshList()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/variables';

.admin-archived-devices {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  overflow: auto;
}

.list-containter {
  background: $theme-color-background-4;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 3rem;
}

.icon-composite {
  display: contents;
}

.icon-composite-main {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 5px;
  background-color: transparent;
  color: $theme-color-primary-5;
  text-align: center;
  cursor: pointer;
}

.icon-composite-child {
  position: relative;
  font-size: 1.8rem;
  left: -1.8rem;
  top: -1.2rem;
  background-color: transparent;
  color: $theme-color-primary-2;
  text-align: center;
  cursor: pointer;
}

.icon-composite:focus,
.icon-composite:hover .icon-composite-highlight {
  color: $theme-color-primary-1 !important;
  //border-color: $theme-color-primary-3;
  //background: $theme-color-secondary-2;
  text-shadow: 0px 0px 20px $theme-color-secondary-2,
    0px 0px 10px $theme-color-secondary-2;
}
</style>
